import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RepositoryService } from './repository.service';
import { GlAcct } from '../_interfaces/gl-acct';
import { Observable } from 'rxjs';
import { GlSub } from '../_interfaces/gl-sub';
import { GlDept } from '../_interfaces/gl-dept';
import { DataTablesResponse } from '../_interfaces/data-tables-response';
import { GlAcctSubledger } from '../_interfaces/gl-acct-subledger';

@Injectable({
  providedIn: 'root'
})
export class GLService {

  constructor(private http: HttpClient, private repository: RepositoryService) { }

  //curl -X GET "https://radiusdemo2.senior-systems.com/api/GL/GLAcct/14510104" -H "accept: text/plain" -H "Authorization: aaa"
  public GetGLAcct(acctNumber: string): Observable<GlAcct> {
    let params = new HttpParams().set("acctNo", acctNumber);
    const obsRet = new Observable<GlAcct>(observer => {
      this.repository.getData('api/GL/GLAcct/' + acctNumber).subscribe(result => {
        var m = result as GlAcct;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  public SearchGLAccts(search: string): Observable<GlAcct[]> {
 
    var dataTablesParameters: any = {};
    dataTablesParameters.draw = 0;
    dataTablesParameters.start = 0;
    dataTablesParameters.length = 1000;
    dataTablesParameters.columns = [];
    dataTablesParameters.search = {};
    dataTablesParameters.search.value = search;
     
    const obsRet = new Observable<GlAcct[]>(observer => {
      this.repository.getDataTablesData('api/GL/GLAccounts/', dataTablesParameters)
        .subscribe(res => {
          var m = (res as DataTablesResponse).data as GlAcct[];
          observer.next(m);
          observer.complete();
        },
            (error) => {
                console.log(error);

          })
    });
    return obsRet;
  }

  //curl -X GET "https://radiusdemo2.senior-systems.com/api/GL/GLSubledger/GYM" -H "accept: text/plain" -H "Authorization: aaa"
  public GetGLSubledger(acctNumber: string): Observable<GlSub> {
    let params = new HttpParams().set("acctNo", acctNumber);
    const obsRet = new Observable<GlSub>(observer => {
      this.repository.getData('api/GL/GLSubledger/' + acctNumber).subscribe(result => {
        var m = result as GlSub;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
    }

    //curl -X GET "https://radiusdemo2.senior-systems.com/api/GL/GLSubledger/GYM" -H "accept: text/plain" -H "Authorization: aaa"
    public GetGLSubledgerAccount(acctNumber: string, subledgerId: string): Observable<GlAcctSubledger> {
        let params = new HttpParams().set("acctNo", acctNumber).set("subledgerId", subledgerId);
        const obsRet = new Observable<GlAcctSubledger>(observer => {
            this.repository.getData('api/GL/GLAcctSubledger/'+acctNumber+'/'+subledgerId).subscribe(result => {
                var m = result as GlAcctSubledger;
                observer.next(m);
                observer.complete();
            });
        });
        return obsRet;
    }

  public SaveAccountBudgetNotesAndProjection(acct: GlAcct): Observable<boolean>{
    const obsRet = new Observable<boolean>(observer => {
      this.repository.update('api/GL/GlAcctNotesAndProjection', acct).subscribe(result => {
        //var m = result as AccountBudgetView[];
        observer.next();
        observer.complete();
      });
    });
    return obsRet;
  }
    public SaveSubledgerBudgetNotesAndProjection(acct: GlAcctSubledger): Observable<boolean> {
    const obsRet = new Observable<boolean>(observer => {
      this.repository.update('api/GL/GlSubledgerNotesAndProjection', acct).subscribe(result => {
        //var m = result as AccountBudgetView[];
        observer.next();
        observer.complete();
      });
    });
    return obsRet;
  }

  //https://radiusdemo2.senior-systems.com/api/GL/GLDepartment/757
  public GetGLDept(deptId: string): Observable<GlDept> {
    //let params = new HttpParams().set("id", deptId);
    const obsRet = new Observable<GlDept>(observer => {
      this.repository.getData('api/GL/GLDepartment/' + deptId).subscribe(result => {
        var m = result as GlDept;
        observer.next(m);
        observer.complete();
      });
    });
    return obsRet;
  }

  //https://radiusdemo2.senior-systems.com/api/GL/GLDepartment/757
  public GetAllGLDepartments(): Observable<GlDept[]> {
    var dataTablesParameters: any = {}; 
    dataTablesParameters.draw = 0;
    dataTablesParameters.start = 0;
    dataTablesParameters.length = 1000;
    dataTablesParameters.columns = [];
    dataTablesParameters.search = {};
    dataTablesParameters.search.value = "";
     
    const obsRet = new Observable<GlDept[]>(observer => {
      this.repository.getDataTablesData('api/GL/GLDepartments/', dataTablesParameters)
        .subscribe(res => {
          var m = (res as DataTablesResponse).data as GlDept[];
          observer.next(m);
          observer.complete();
        },
            (error) => {
                console.log(error);
           
          })
    });
    return obsRet;
  }

}
